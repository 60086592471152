import { canCumulateDataTypes, ChartSettingsT } from 'business/definitions';
import { snakify } from 'helpers/syntaxHelper';
import i18n from 'i18n';
import { format } from 'date-fns';
import { DEFAULT_CHART_TITLE } from 'business/constants';
import { removeEmptyKeys } from 'helpers/helpers';
import { ChartDataT } from '../updateChartThenUpdateVizSettings';
import { SettingsState } from './settingsReducer';

export const getChartTitle = (settingsChartTitle: string) => {
    if (
        settingsChartTitle === i18n.t(DEFAULT_CHART_TITLE) ||
        settingsChartTitle === ''
    ) {
        return `${i18n.t(DEFAULT_CHART_TITLE)} - ${format(
            new Date(),
            'dd/MM/yyyy',
        )}`;
    }
    return settingsChartTitle;
};

export const getChartData = (
    explore: ExploreQueryT,
    settings: Pick<
        SettingsState,
        'chartTitle' | 'selectedViz' | 'chartSettings'
    >,
): ChartDataT => {
    // we compute the title if it is still the default 'Nouveau graphique' one
    const title = getChartTitle(settings.chartTitle);

    return {
        metrics: explore.metrics.filter((e) => e.field),
        dimensions: explore.dimensions.filter((e) => e.field),
        filters: explore.filters.filter((e) => e.field),
        vizType: settings.selectedViz,
        title,
        // We clean the settings in case the user set a field to '' or null

        settings: removeEmptyKeys(settings.chartSettings),
    };
};

const shouldResetCumulatedDimension = (
    dimension: ResponseColumnT[],
): boolean => {
    return (
        dimension.filter((d) => d.type && canCumulateDataTypes.includes(d.type))
            .length !== 1
    );
};

export const shouldResetIsStacked = (
    chartSettings: ChartSettingsT,
): boolean => {
    //We should set isStackedBar to False if we are using a secondaryYAxis, and if the number of metrics used is stricly less than 3
    if (chartSettings.isStackedBars && chartSettings.secondaryYAxis) {
        const yLength =
            chartSettings.chartConception?.filter(
                (conception) => conception.chartDimensionKey === 'y',
            ).length || 0;
        return yLength < 3;
    }
    return false;
};
/**
 * Reinitialized columns settings. It reinitialized metrics cumulated too if we cannot cumulate the dimensions.
 */
export const getResetSettings = (
    queryMetrics: QueryMetricT[],
    columns: ResponseColumnT[],
): Pick<ChartSettingsT, 'metricsCumulated'> => {
    // Columns have changed, we reset some settings to avoid breaking the layout
    const resetSettings: ChartSettingsT = {};

    // Checking if we should reset the cumulated setting
    const metricsFields = queryMetrics.map((m) => m.field);
    const cumulatedDimensions = columns.filter(
        (col) => !metricsFields.includes(snakify(col.key)),
    );
    if (shouldResetCumulatedDimension(cumulatedDimensions)) {
        resetSettings.metricsCumulated = [];
    }
    return resetSettings;
};
