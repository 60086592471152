import {
    BACK_OFFICE,
    BUDGET,
    DECISION,
    HUMAN_RESOURCES,
    ModuleT,
} from 'business/constants';

export type ModuleDetailsT = { label: string; available: boolean };

export const MODULES: Record<ModuleT, ModuleDetailsT> = {
    [DECISION]: { label: 'modules.decision', available: true },
    [BUDGET]: { label: 'modules.budget', available: true },
    [BACK_OFFICE]: { label: 'modules.backOffice', available: true },
    [HUMAN_RESOURCES]: {
        label: 'modules.humanResources',
        available: true,
    },
};

export const CSV_IMPORT = 'csv_import';
const UNIFIED_ORG_CHARTS = 'unified_org_charts';
export const ESSENTIAL = 'decision_essential';
export const ALERTS = 'alerts';
export const FINANCE_SOFTWARE_SWITCH = 'finance_software_switch';

export type ExtraFeatureT =
    | typeof CSV_IMPORT
    | typeof UNIFIED_ORG_CHARTS
    | typeof ESSENTIAL
    | typeof ALERTS
    | typeof FINANCE_SOFTWARE_SWITCH;

export const EXTRA_FEATURES: Record<
    ModuleT,
    Partial<Record<ExtraFeatureT, ModuleDetailsT>>
> = {
    [DECISION]: {
        [ESSENTIAL]: {
            label: 'extraFeatures.essential',
            available: true,
        },
        [CSV_IMPORT]: {
            label: 'extraFeatures.csvImport',
            available: true,
        },
        [UNIFIED_ORG_CHARTS]: {
            label: 'extraFeatures.unifiedOrgCharts',
            available: true,
        },
        [ALERTS]: {
            label: 'extraFeatures.alerts',
            available: true,
        },
    },
    [BUDGET]: {},
    [HUMAN_RESOURCES]: {},
    [BACK_OFFICE]: {},
};
