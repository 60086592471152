import { DataTypes as DT, UserRole } from 'business/definitions';

export enum BudgetPhases {
    ARBITRAGE = 'arbitrage',
    VOTE = 'voté',
    CADRAGE = 'cadrage',
    PROPOSITION_SERVICES = 'proposition_services',
    ARCHIVED = 'archived',
    SUPPLEMENTARY_BUDGET = 'supplementary_budget',
}

export enum BudgetSections {
    INVESTMENT = 'INVESTMENT',
    OPERATING = 'OPERATING',
}

export enum BudgetExpenseOrIncome {
    EXPENSE = 'EXPENSE',
    INCOME = 'INCOME',
}
export enum BudgetAtterrissageOrPluriannual {
    ATTERRISSAGE = 'atterrissage',
    PLURIANNUAL = 'pluriannual',
}

export enum BudgetRealOrOrder {
    REAL = 'REAL',
    ORDER = 'ORDER',
}

export enum BudgetBys {
    GESTIONNAIRE = 'gestionnaire.name',
    ORG_CHART_FINANCE = 'orgChartFinance.tree',
}

// WARNING: this enum references its backend equivalent: InscriptionStatusCategory
// make sure that the two are the same if modified
export enum InscriptionStatusCategory {
    IN_PROGRESS = 'inProgress',
    TO_VALIDATE = 'toValidate', // also refers to TO_ARBITRATE
    REJECTED = 'finalError',
    ACCEPTED = 'finalOK',
    VOTED = 'voted',
    // value NULL used only for statuses without category associated (empty, archived)
    // so not in backend equivalent
    NULL = 'null',
}

// WARNING: this enum references its backend equivalent: ProjectionStatuses
// make sure that the two are the same if modified
export enum ProjectionStatuses {
    TODO = 'todo',
    UP_TO_DATE = 'up_to_date',
}

export const DEFAULT_DYNAMIC_VALUE = '3';
export const DEFAULT_TIME_SCALE = 'year';
export const DEFAULT_PAGE_SIZE = 10;

export const MAX_TABLE_LENGTH = 20000;
export const NUMERIC_TYPES: readonly DataTypesT[] = [
    DT.integer,
    DT.float,
    DT.fraction,
    DT.money,
    DT.year,
];
export const ZERO_MARK_FRANCE_LAT = 48.853276;
export const ZERO_MARK_FRANCE_LON = 2.348713;
export const DEFAULT_MAP_ZOOM = 13;
export const LAYOUT_BREAKPOINTS = { md: 480, xs: 0 };
export const BREAKPOINT_COLUMNS = { md: 10, xs: 2 };

export const DASHBOARD_ROW_HEIGHT = 40;
export const MILISECONDS_IN_DAY = 1000 * 3600 * 24;
export const MONTH_COLUMN_KEY = 'month';
export const DAY_COLUMN_KEY = 'day';
export const QUARTER_COLUMN_KEY = 'quarter';
export const YEARLY_COMPARISON_KEYS = [
    MONTH_COLUMN_KEY,
    DAY_COLUMN_KEY,
    QUARTER_COLUMN_KEY,
];

export const YEAR_COLUMN_KEY = 'year';

export const HOME_PLACEHOLDER_DASHBOARD = 'home';
export const DECISION = 'decision';
export const BUDGET = 'budget';
export const HUMAN_RESOURCES = 'human_resources';
export const BACK_OFFICE = 'backoffice';

export type ModuleT =
    | typeof DECISION
    | typeof BUDGET
    | typeof HUMAN_RESOURCES
    | typeof BACK_OFFICE;

export const UNKNOWN_DATUM = 'unknown';

export const TODAY = 'today';

export const ORG_CHART_TABLE = 'org_chart';
export const HISTORICAL_ORG_CHART_HR_TABLE = 'historical_org_chart_hr';
export const GESTIONNAIRE_FIELD = 'gestionnaire.name';

export const PAY_COMPONENT_CATEGORY = 'wage_type.pay_component';
export const UNMAPPED = 'unmapped';
export const DUMMY_ROOT_VALUE = 'ROOT';
export const EXCLUDED_VALUE = 'special_excluded';

export const DEFAULT_CHART_TITLE = 'chart.defaultTitle';
export const DEFAULT_EXPORTED_CHART_TITLE = 'chart.exportDefaultTitle';
export const DEFAULT_DASHBOARD_TITLE = 'dashboard.defaultTitle';
export const EXTRACT_OPERATION = 'extract';

// FIXME: duplicated with theme.js
export const MANTY_COLORS = {
    lightBlue: '#7ECEFD',
    blue: '#4163A5',
    cyan: '#2185c5',
    yellow: '#F8BD50',
    orange: ' #E9815F',
    red: '#F14F5B',
    white: '#FFFFFF',
    lightGrey: '#EFEFEF',
    mediumGrey: '#899FB1', // titles
    darkGrey: '#3F4750', // text and titles
    green: '#529F5E',
} as const;

export const WIDGET_TYPE = {
    chart: 'chart',
    title: 'title',
    paragraph: 'paragraph',
    separator: 'separator',
    navlink: 'navlink',
    pagebreak: 'pagebreak',
} as const;

export const AGGREGATION_TYPE = {
    list: 'list',
    sum: 'sum',
    number: 'number',
    average: 'average',
} as const;

export const ADMIN = 'admin';
export const RAWDATA = 'rawdata';
export const BUDGET_USER = 'budget_user';
export const EXPLORE_USER = 'explore_user';
export const DECISION_USER = 'decision_user';
export const HUMAN_RESOURCES_USER = 'human_resources_user';
export const ADMIN_BUDGET = 'adminbudget';
export const ACCESS_DAF = 'access_DAF';
export const ADMIN_CATEGORIES = 'admin_categories';
export const SUPER_FINANCES_WRITER = 'super_finances_writer';
export const ACCESS_CADRAGE_GESTION = 'access_cadrage_gestion';
export const ACCESS_BUDGETS_GESTION = 'access_budgets_gestion';
export const ADMIN_BUDGET_ROLES: UserRole[] = [
    ACCESS_DAF,
    ADMIN_CATEGORIES,
    SUPER_FINANCES_WRITER,
    ACCESS_CADRAGE_GESTION,
    ACCESS_BUDGETS_GESTION,
];
export const SUPPORT = 'support';

export type RoleWithSubRoles = { header: string; roles: UserRole[] };

export const ROLES_BY_MODULE: Partial<
    Record<ModuleT, readonly (UserRole | RoleWithSubRoles)[]>
> = {
    [DECISION]: [DECISION_USER, EXPLORE_USER, RAWDATA],
    [BUDGET]: [
        BUDGET_USER,
        { header: ADMIN_BUDGET, roles: ADMIN_BUDGET_ROLES },
    ],
    [HUMAN_RESOURCES]: [HUMAN_RESOURCES_USER],
} as const;
// Users fields
export const IS_ACTIVE = 'isActive';
export const EMAIL = 'email';
export const ROLES = 'roles';
export const SCOPES = 'scopes';
export const USER_RIGHTS = 'userRights';

export const INITIAL = 'initial';
export const LOADING = 'loading';
export const SUCCESS = 'success';
export const FAILED = 'failed';
export const UNAUTHORIZED = 'unauthorized';

// Hack for demo, unusable for regular clients
export const ADVANCED_VIZ_TYPE = 'advanced';
export const PYRAMID_VIZ_TYPE = 'pyramid';

// SCOPE ACCESS TYPES
export const SCOPE_ACCESS_TYPE_FULL_ACCESS = 'full_access';
export const SCOPE_ACCESS_TYPE_RESTRICTED = 'restricted';

export const MANTY_AUTH = 'manty_auth';
export const SSO_SUFFIX = '_sso';

// REGEX
export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const INTEGER_REGEX = /^[0-9]*$/;
export const FLOAT_REGEX = /^[+-]?([0-9]*[.])?[0-9]+$/;

// User locations in the app
export const HOME_LOCATION = 'home';
export const DASHBOARDS_LOCATION = 'dashboards';
export const INDICATORS_LOCATION = 'indicators';
export const EXPLORER_LOCATION = 'explorer';
export const BUDGET_AS_RESOURCE_LOCATION = 'budget_as_resource';
export const BUDGET_OVERVIEW_LOCATION = 'budget_overview';
export const ADMIN_BUDGET_LOCATION = 'budget_admin';
export const HR_PAY_STATE_HOME_LOCATION = 'pay_state_home';
export const HR_RETROSPECTIVE_HOME_LOCATION = 'retrospective_home';
export const HR_RETROSPECTIVE_ITEM_LOCATION = 'retrospective';
export const HR_PAY_STATE_ITEM_LOCATION = 'pay_state';
export const HR_SIMULATION_HOME_LOCATION = 'simulation_home';
export const HR_SIMULATION_ITEM_LOCATION = 'simulation';
export const SETTINGS_LOCATION = 'settings';
export const SUPPORT_LOCATION = 'support';

// Maps a URL with a location
export const LOCATION_MAPPING: Record<string, string> = {
    home: HOME_LOCATION,
    dashboard: DASHBOARDS_LOCATION,
    dashboards: DASHBOARDS_LOCATION,
    indicators: INDICATORS_LOCATION,
    explore: EXPLORER_LOCATION,
    budget_proposal: BUDGET_AS_RESOURCE_LOCATION,
    budget_home: BUDGET_AS_RESOURCE_LOCATION,
    budget_operation_view: BUDGET_AS_RESOURCE_LOCATION,
    budget_overview: BUDGET_OVERVIEW_LOCATION,
    budget_admin: ADMIN_BUDGET_LOCATION,
    pay_state_home: HR_PAY_STATE_HOME_LOCATION,
    pay_state: HR_PAY_STATE_ITEM_LOCATION,
    simulation_home: HR_SIMULATION_HOME_LOCATION,
    simulation: HR_SIMULATION_ITEM_LOCATION,
    retrospective_home: HR_RETROSPECTIVE_HOME_LOCATION,
    retrospective: HR_RETROSPECTIVE_ITEM_LOCATION,
    settings: SETTINGS_LOCATION,
    profile: SETTINGS_LOCATION,
    admin: SETTINGS_LOCATION,
    mappings: SETTINGS_LOCATION,
    cellar: SETTINGS_LOCATION,
    integrations: SETTINGS_LOCATION,
    support: SUPPORT_LOCATION,
};

export const ORDERED_BUDGET_PHASES = [
    BudgetPhases.CADRAGE,
    BudgetPhases.PROPOSITION_SERVICES,
    BudgetPhases.ARBITRAGE,
    BudgetPhases.VOTE,
    BudgetPhases.SUPPLEMENTARY_BUDGET,
    BudgetPhases.ARCHIVED,
] as const;

// List of the ordered budget phase that are active inside manty
export const MANTY_ORDERED_BUDGET_PHASES = [
    BudgetPhases.PROPOSITION_SERVICES,
    BudgetPhases.ARBITRAGE,
    BudgetPhases.VOTE,
    BudgetPhases.SUPPLEMENTARY_BUDGET,
];

// List of the ordered budget phases that are movable
export const MANTY_ORDERED_MOVABLE_BUDGET_PHASES = [
    BudgetPhases.PROPOSITION_SERVICES,
    BudgetPhases.ARBITRAGE,
    BudgetPhases.VOTE,
    BudgetPhases.SUPPLEMENTARY_BUDGET,
    BudgetPhases.ARCHIVED,
];

export const DEFAULT_BUDGET_BY = BudgetBys.ORG_CHART_FINANCE;

// CSV import V1: import csv according to predefined tables in our datalake
export const CSV_TABLES_V1 = {
    demo: ['temp_salt_consumption', 'temp_electricity_plant', 'medium_loan'],
    app: [
        'temp_salt_consumption',
        'temp_electricity_plant',
        'medium_loan',
        'temp_market_lot',
        'norm_switch_ciril',
        'norm_switch_coriolis',
        'norm_switch_astre',
    ],
    bayonne: ['norm_switch_sedit'],
    bayonne_dev: ['norm_switch_sedit'],
    bernay: ['norm_switch_sedit'],
    clichy: ['norm_switch_ciril', 'political_admin_mapping'],
    communaute_paysbasque: [
        'temp_salt_consumption',
        'temp_electricity_plant',
        'medium_loan',
        'temp_market_lot',
    ],
    ct975: ['social_support'],
    anglet: ['temp_anglet_events'],
    'est-ensemble': ['temp_market_lot', 'temp_projects_portfolio'],
    grandperigueux: ['norm_switch_ciril'],
    grand_lac: ['norm_switch_ciril'],
    laqueueenbrie: ['norm_switch_ciril'],
    lonslesaunier: ['norm_switch_ciril'],
    limoges: ['norm_switch_astre'],
    rivieradulevant: ['norm_switch_sedit'],
    valleesud: ['norm_switch_ciril'],
    vannes: ['norm_switch_coriolis'],
    yerres: ['failure'],
} as const;

/**
 * Same as react-query default cache time
 *  */
export const STALE_TIME = 300000;

export const PARTNERS_EMAIL = {
    gig: 'manty@gig-conseil.fr',
};
